/* pipes.modules.ts */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RelativeTime } from './relativeTime.pipe';
import { GroupByPipe } from './groupBy.pipe';
import { MomentPipe } from './moment.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { SafePipe } from './safe.pipe';
import { KeysPipe } from './keys.pipe';

@NgModule({
  declarations: [RelativeTime, GroupByPipe, MomentPipe, SafeHtmlPipe, SafePipe,KeysPipe],
  imports: [IonicModule],
  exports: [RelativeTime, GroupByPipe, MomentPipe, SafeHtmlPipe, SafePipe,KeysPipe],
})
export class PipesModule {}
