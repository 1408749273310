export const environment = {
  production: true,
  sentryUrl: 'https://c77d11a8c2d842c293f9b992e221bca6@o508805.ingest.sentry.io/4504095855083520',
  apiUrl: 'https://hub-lideres.devstaging.live/api/',
  shareUrl: 'https://hub-lideres.devstaging.live/share',
  firebase: {
    apiKey: 'AIzaSyDNSj8UBW93hHz3l1au5zsc9XAs-iUFfd0',
    authDomain: 'lds-sudca-lideres-stg.firebaseapp.com',
    databaseURL: 'https://lds-sudca-lideres-stg.firebaseio.com',
    projectId: 'lds-sudca-lideres-stg',
    storageBucket: 'lds-sudca-lideres-stg.appspot.com',
    messagingSenderId: '977538328674',
    appId: '1:977538328674:web:89a70d53b853c387f8893b',
    measurementId: 'G-F1GDZ24XEW',
  },
  auth_config: {
    client_id: '0oang9ahg2g6cqK95357',
    server_host: 'https://id.churchofjesuschrist.org/oauth2/default',
    redirect_url: 'lideresca://callback',
    end_session_redirect_url: 'lideresca://logout',
    scopes: 'openid profile offline_access',
    pkce: true,
    loadUserInfo: true
    },
};
