import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { ShareService } from '../../services/share.service';
import { AnalyticsService } from '../../services/analytics.service';
import { get, set } from '../../services/storage.service';

import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionsCompont implements OnInit {
  //TODO CAMBIAR ICONO FAVORITOS
  @Input('item') item: any;
  @Input('shareOn') shareOn: any = true;
  isLike = false;
  constructor(private shareService: ShareService, private analyticsService: AnalyticsService, private restService: RestService) {}

  ngOnInit() {
  }
  ionViewWillEnter() {}

  public share(item = null) {
    this.shareService.share(item, this.item.typeString);
  }
  async open(item) {
    this.analyticsService.logEvent('External_Link', { link: item.external_link, CONTENT_TYPE: this.item.typeString, ITEM_ID: item.id });
    await Browser.open({ toolbarColor: '#6AD0AE', url: item.external_link });
  }
}
