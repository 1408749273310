import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

import { ShareService } from '../../services/share.service';
import { EventEmitterService } from '../../services/event-emitter.service';

import { AnalyticsService } from '../../services/analytics.service';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';
import { Browser } from '@capacitor/browser';
import brightcovePlayerLoader from '@brightcove/player-loader';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaComponent implements OnInit {
  // get the component instance to have access to plyr instance
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;

  // or get it from plyrInit event
  player: Plyr;

  played(event: Plyr.PlyrEvent) {
    console.log('played', event);
  }
  removeTag($event) {
    $event.media.removeAttribute('playsinline');
  }
  play(): void {
    this.player.play(); // or this.plyr.player.play()
  }
  @Input('item') item: any = [];
  @Input('featured') featured: any;
  show = true;
  @Input('type') type: any = null;
  @Input('options') options: any = false;
  constructor(private shareService: ShareService, private analyticsService: AnalyticsService, private eventEmitterService: EventEmitterService) {}

  ngOnInit() {
    let a = this;
   //if (this.eventEmitterService.resetMediaSubsVar===undefined) {
   //  this.eventEmitterService.resetMediaSubsVar = this.eventEmitterService.resetMedia.subscribe((data) => {
   //    this.show = false;
   //  });
   //}
    if (this.item) {
      setTimeout(() => {
        let i = 0;
        for (let media of this.item) {
          if (media.type==='brightcove') {
            console.log(media.url);
            let videoId = media.url.split('videoId=');
            let accountId = media.url.split('/');
            if (videoId[1] && accountId[3]) {
              brightcovePlayerLoader({
                refNode: '#media-' + i,
                accountId: accountId[3],
                videoId: videoId[1],
                refNodeInsert: 'replace',
                embedId: 'default',
                options: {
                  autoplay: false,
                },
              })
                .then(function (success) {
                  // The player has been created!
                  console.log('success', success);
                })
                .catch(function (error) {
                  // Player creation failed!
                  console.log('error', error);
                });
            }
          }
          i++;
        }
      }, 1);
    }
  }

  ionViewWillEnter() {
    this.show = false;
    document.getElementsByTagName('video')[0].removeAttribute('playsinline');
    document.getElementsByTagName('video')[0].removeAttribute('autoplay');
  }
  public download($event, item = null) {
    $event.preventDefault();
    $event.stopPropagation();
    this.analyticsService.logEvent('bt_download', { from: 'Media', link: item.link });
    this.shareService.share(item, 'media');
  }
  async open($event, item = null) {
    if (item.type==='file') {
      $event.preventDefault();
      $event.stopPropagation();
      if (item.type==='iframe') {
        item.link = item.url;
      } else if (item.type==='jpg') {
        item.link = item.image.url;
      } else if (item.type==='video') {
        item.link = item.image.video;
      } else if (item.type==='file') {
        item.link = item.file.url;
      }
      this.analyticsService.logEvent('bt_open', { from: 'Media', link: item.link });
      await Browser.open({ toolbarColor: '#6AD0AE', url: item.link });
    }
  }
  public share($event, item = null) {
    $event.preventDefault();
    $event.stopPropagation();
    this.analyticsService.logEvent('bt_share', { from: 'Media', link: item.link });
    this.shareService.share(item, 'media');
  }
}
