
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'dateFormat' })
export class MomentPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
        moment.locale(this.translateService.getDefaultLang())
    }
    transform(value: Date | moment.Moment, dateFormat: string): any {
        return moment(value).format(dateFormat);
    }
}