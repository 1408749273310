import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { MediaComponent } from './media/media.component';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { SearchBarComponent } from './searchBar/searchBar.component';
import { ActionsCompont } from './actions/actions.component';
import { TagsComponent } from './tags/tags.component';
import { FavoriteComponent } from './favorite/favorite.component';

import { PipesModule } from '../pipes/pipes.module';
import { PlyrModule } from 'ngx-plyr';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ActionsCompont,
    HeaderComponent,
    MediaComponent,
    SearchBarComponent,
    TagsComponent,
    FavoriteComponent,
  ],
  imports: [
    TranslateModule,
    FormsModule,
    CommonModule,
    PipesModule,
    RouterModule,
    IonicModule,
    PlyrModule,
  ],
  exports: [ActionsCompont, HeaderComponent, MediaComponent, SearchBarComponent, TagsComponent,FavoriteComponent],
})
export class ComponentsModule {}
