import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventEmitterService {

    private  emitters: { [notificationName: string]: EventEmitter<any> } = {};


    get(notificationName: string){
        if (!this.emitters[notificationName])
            this.emitters[notificationName] = new EventEmitter<any>();
            
        return this.emitters[notificationName];
    }
}

/** 
to SUBSCRIBE:

constructor(){
}

TO EMIT: 

eventEmitterService.get("notificationName").emit(payload); 

to UNSUBSCRIBE:

ngOnDestroy(){
    this.subscriptions.unsubscribe();
}
*/