// Must import the package once to make sure the web support initializes
import { Injectable } from '@angular/core';

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
//import { Device } from '@capacitor/device';
//const deviceInfo = Device.getInfo();

/*
firebase web app configuration options
 * */
//
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {
    this.setUserIdBase();
  }

  public async trackView(title) {
   //if ((await deviceInfo).platform !== 'web') {
   //  FirebaseAnalytics.setScreenName({ screenName: title, nameOverride: title });
   //}
  }

  public async setUserIdBase() {
   //if ((await deviceInfo).platform !== 'web') {
   //  const info = await Device.getId();
   //  FirebaseAnalytics.setUserId({
   //    userId: info.uuid,
   //  });
   //}
  }
  public async setUserId(uid) {
   // if ((await deviceInfo).platform !== 'web') {
   //   // FirebaseAnalytics.setUserId({
   //   //   userId: uid.value});
   // }
  }
  public async setUserProperty(key, value) {
   // if ((await deviceInfo).platform !== 'web') {
   //   FirebaseAnalytics.setUserProperty({ name: key, value: value });
   // }
  }

  public async logEvent(event, data = {}) {
  // if ((await deviceInfo).platform !== 'web') {
  //   FirebaseAnalytics.logEvent({ name: event, params: data });
  // }
  }
}
