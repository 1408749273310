import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

import { EventEmitterService } from '../event-emitter.service';

@Injectable({
  providedIn: 'root',
})
export class DeployService {
  private progress = 0;
  constructor(
    private deploy: Deploy,
    private eventEmitterService: EventEmitterService,
    private modalController: ModalController
  ) {}

  async init() {
    //this.configureDeploy();
    //this.changeToBetaChannel();
    this.performManualUpdate();
  }

  public async showDeploy() {
    const modal = await this.modalController.create({
      mode: 'ios',
      keyboardClose: false,
      backdropDismiss: false,
      component: DeployPage,
      cssClass: 'deploy-modal',
    });
    return await modal.present();
  }
  async changeToBetaChannel() {
    await this.deploy.configure({ channel: 'Master' });
  }
  async configureDeploy() {
    const config = {
      appId: '',
    };
    await this.deploy.configure(config);
  }
  async deleteVersion() {
    const versions = await this.deploy.getAvailableVersions();
    this.deploy.deleteVersionById(versions[0].versionId);
  }
  async performManualUpdate() {
    const update = await this.deploy.checkForUpdate();
    console.log(update.available);
    if (update.available) {
      this.showDeploy();
      await this.deploy.downloadUpdate((progress) => {
        console.log(progress);
        this.progress = progress;
        //TODO
        // this.eventEmitterService.publish('deployProgress', {
        //   progress: this.progress,
        //   type: 'download',
        // });
      });
      await this.deploy.extractUpdate((progress) => {
        console.log(progress);
        this.progress = progress;
        //TODO
        // this.eventEmitterService.publish('deployProgress', {
        //   progress: this.progress,
        //   type: 'extract',
        // });
      });
      await this.deploy.reloadApp();
    }
  }
  async performAutomaticUpdate() {
    try {
      const currentVersion = await this.deploy.getCurrentVersion();
      const resp = await this.deploy.sync(
        { updateMethod: 'auto' },
        (percentDone) => {
          console.log(`Update is ${percentDone}% done!`);
        }
      );
      if (!currentVersion || currentVersion.versionId !== resp.versionId) {
        // We found an update, and are in process of redirecting you since you put auto!
      } else {
        // No update available
      }
    } catch (err) {
      // We encountered an error.
    }
  }
}

// Agregar en app.modules.ts
//declarations: [DeployPage],
//entryComponents: [ DeployPage],
/*  */
@Component({
  selector: 'app-deploy',
  templateUrl: 'deploy.page.html',
  styleUrls: ['deploy.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeployPage implements OnInit {
  public lang;
  public progress = 0;
  public decimal = 0;
  public type = 'download';
  public textt = '';
  constructor(
    private modalController: ModalController,
    private eventEmitterService: EventEmitterService,
    private translate: TranslateService
  ) {}
  ngOnInit() {

    //TODO
    // if (this.eventEmitterService.deployProgressSubsVar===undefined) {
    //   this.eventEmitterService.deployProgressSubsVar =
    //     this.eventEmitterService.deployProgress.subscribe((data) => {
    //       this.progress = data.progress;
    //       this.decimal = data.progress / 100;
    //       this.type = data.type;
    //       this.translate.get('DEPLOY').subscribe(async (res) => {
    //         this.textt = this.type==='download' ? res.install : res.update;
    //       });
    //       if (this.progress===100 && this.type==='extract') {
    //         this.modalController.dismiss({
    //           dismissed: true,
    //         });
    //       }
    //     });
    // }
  }
}
