import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input('title') title: any;
  @Input('defaultHref') defaultHref: any;
  @Input('search') search: boolean = false;

  constructor() {}

  ngOnInit() {}
  ionViewWillEnter() {}
}
