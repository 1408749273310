import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { EventEmitterService } from '../../services/event-emitter.service'
import { NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './searchBar.component.html',
  styleUrls: ['./searchBar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchBarComponent implements OnInit {

  constructor(
    private eventEmitterService: EventEmitterService,
    private navController: NavController) {}

  ngOnInit() {}
  ionViewWillEnter() {}


   handleChange(event) {
      this.eventEmitterService.get("searchbar").emit(event.target.value);
    
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //         q: JSON.stringify(event.target.value)
  //     }
  //   };
  //   console.log(event.target.value);
  //   if(event.target.value == ''){
  //     this.navController.navigateForward(['/tabs/home'], navigationExtras);
  //   } else{
  //     this.navController.navigateForward(['/search'], navigationExtras);

  //   }
  }

}
