import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { get, set } from './storage.service';
import { TranslateService } from '@ngx-translate/core';

import { HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

import { FCM } from '@capacitor-community/fcm';
import { App } from '@capacitor/app';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    public translateService: TranslateService,
    public toastController: ToastController,
    public authService: AuthService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  init() {
    //if (Capacitor.platform !== 'web') {
    this.registerPush();
    //}
  }

  private async registerPush() {
    PushNotifications.requestPermissions().then(async (result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        // // register for push
        //const info = await App.getInfo();
        //let version = info.version;
        PushNotifications.register()
          .then(() => {
            this.getToken();
            //
            // Subscribe to a specific topic
            // you can use `FCM` or just `fcm`
            //  FCM.subscribeTo({ topic: version })
            //    .then((r) => console.log(`subscribed to topic`))
            //    .catch((err) => console.log(err));
            if (Capacitor.isNativePlatform()) {
              FCM.subscribeTo({ topic: 'all' })
                .then((r) => console.log(`subscribed to topic`))
                .catch((err) => console.log(err));
            }
          })
          .catch((err) => console.log(JSON.stringify(err)));

        // //
        // // external required step

        // //
        // // Unsubscribe from a specific topic
        // fcm
        // .unsubscribeFrom({ topic: 'test' })
        // .then(() => alert(`unsubscribed from topic`))
        // .catch((err) => console.log(err));

        // //
        // // Get FCM token instead the APN one returned by Capacitor
        // fcm
        // .getToken()
        // .then((r) => alert(`Token ${r.token}`))
        // .catch((err) => console.log(err));

        // //
        // // Remove FCM instance
        // fcm
        // .deleteInstance()
        // .then(() => alert(`Token deleted`))
        // .catch((err) => console.log(err));
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      console.log('My token: ' + JSON.stringify(token));
      set('token', JSON.stringify(token));
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        const data = notification.data;
        console.log('Push received: ' + JSON.stringify(notification));
        this.presentToast(data);
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        console.log(
          'Action performed: ' + JSON.stringify(notification.notification)
        );
        let url = '';
        if (data['custom_url']) {
          url = data['custom_url'];
        } else {
          url = '/notifications/notifications/' + data['notification_id'];
        }
        this.router.navigate([url]);
      }
    );
  }

  getToken() {
    if (Capacitor.isNativePlatform()) {
      FCM.getToken()
        .then((r) => {
          localStorage.setItem('fcmToken', r.token);

          // this.authService
          //   .addDevice(r.token)
          //   .pipe(take(1))
          //   .subscribe(
          //     // next:
          //     (ok: boolean) => {},
          //     (err: HttpErrorResponse) => {}
          //   );
          return true;
        })
        .catch((err) => console.log(err));
    }
  }
  async presentToast(data) {
    this.translateService.get('FCM').subscribe(async (res) => {
      const toast = await this.toastController.create({
        duration: 3000,
        header: data['title'],
        message: data['body'],
        position: 'bottom',
        buttons: [
          {
            text: res.go,
            handler: () => {
              if (data) {
                let url = '';
                if (data['custom_url']) {
                  url = data['custom_url'];
                } else {
                  url =
                    '/notifications/notifications/' + data['notification_id'];
                }
                this.ngZone.run(() => this.router.navigate([url])).then();
              }
            },
          },
        ],
      });
      toast.present();
    });
  }
}
