import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ShareService } from '../../services/share.service';
import { RestService } from '../../services/rest.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TagsComponent implements OnInit {
  @Input('type') type: any;
  @Input('item') item: any;
  public items: any = [];
  constructor(private restService: RestService, private shareService: ShareService, private analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.items = this.item ? this.item : [];
  }
  public open(tags, item) {
    this.analyticsService.logEvent('bt_open', { link: tags.title, from: item.title });
  }
}
