import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { RestService } from './rest.service';

import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private alertController: AlertController, private restService: RestService) {}

  public async checkUpdates() {
  //const info = await App.getInfo();
  //let version = info.version;
  //const deviceInfo = await Device.getInfo();
  //let platform = deviceInfo.platform;

  //  if (platform !== 'web') {
  //   return false;
  //   }
  //    this.restService.getVersion(platform, version).subscribe((data) => {
  //      if (data && data['url'] !== 'no new version') this.presentAlertVersion(data['url']);
  //    });
  }
  async presentAlertVersion(data) {
    const alert = await this.alertController.create({
      message: 'Hay una nueva versión disponible. ¿Deseás descargarla ahora?',
      buttons: [
        {
          text: 'Más tarde',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
        {
          text: 'Descargar',
          handler: async () => {
            await Browser.open({ toolbarColor: '#6AD0AE', url: data });
          },
        },
      ],
    });
    await alert.present();
  }
}
