import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { Share } from '@capacitor/share';

import { AnalyticsService } from './analytics.service';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { TranslateService } from '@ngx-translate/core';
import { FileSharer } from 'capacitor-plugin-filesharer';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(
    public translateService: TranslateService,
    public loadingController: LoadingController,
    private analyticsService: AnalyticsService,
    private restService: RestService
  ) {}

  async getBase64(url) {
    console.log(url);

    var res = await fetch(url);
    console.log(res);

    var blob = await res.blob();
    console.log(blob);

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          console.log(resolve);
          resolve(reader.result);
        },
        false
      );

      reader.onerror = (error) => {
        console.log(error);

        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
  async shareApi(item, loading = null) {
    this.translateService.get('SHARE').subscribe(async (res) => {
      let shareRet = await Share.share({
        text: item.name,
        url: item.link,
        dialogTitle: res.attached,
      });
    });
  }
  async share(item = null, type = null) {
    console.log(type, item);
    this.translateService.get('SHARE').subscribe(async (res) => {
      if (type === 'app') {
        this.analyticsService.logEvent('SHARE-APP');
        let shareRet = await Share.share({
          title: res.appTitle,
          url: environment.shareUrl,
          dialogTitle: res.appDialogTitle,
        });
      } else if (type === 'media' || type === 'song') {
        //   let text = '¿Conoces la aplicación del Plan del Área Sudamérica Sur? Obtenla aquí:';
        //   let url = 'https://plandelareasudamericasur.com.ar/share/';
        //   let type = 'app';
        //   this.shareService.share($event,text,url,type);

        if (item.type === 'brightcove' || item.type === 'youtube') {
          item.link = item.url;
        } else if (item.type === 'image') {
          item.link = item.image_url;
        } else if (item.type === 'file') {
          item.link = item.file_url;
        } else if (item.type === 'video') {
          item.link = item.file_url;
        } else if (type === 'song') {
          item.link = item.url;
          item.title = item.name;
        } else {
          item.link = environment.shareUrl;
        }

        this.analyticsService.logEvent('SHARE', {
          link: item.link,
          CONTENT_TYPE: type,
          ITEM_ID: 'MEDIA',
        });
        if (
          item.type === 'image' ||
          item.type === 'video' ||
          item.type === 'file' ||
          type === 'song'
        ) {
          const loading = await this.loadingController.create({
            cssClass: 'loading',
            message: res.loadingFile,
          });
          await loading.present();
          this.restService
            .getBase64(item.link.replace(/^.*\/\/[^\/]+/, '').replace('/', ''))
            .subscribe((data) => {
              //item.link = data;;
              loading.dismiss();
              FileSharer.share({
                header: res.appTitle,
                filename: data['filename'],
                base64Data: data['base64Data'],
                contentType: data['contentType'],
              })
                .then(() => {})
                .catch((error) => {
                  console.error('File sharing failed', error.message);
                });
            });
        } else {
          this.shareApi(item);
        }
      } else {
        this.analyticsService.logEvent('SHARE', {
          link: item.share,
          CONTENT_TYPE: type,
          ITEM_ID: item.slug,
        });
        // if (item.external_url && item.external_url !== '') {
        //   item.share = item.external_url;
        // }
        console.log(item.share);
       let shareRet = await Share.share({
          //title: item.title,
          text: item.title,
          url: item.share,
          dialogTitle: res.link,
        });
      }
    });
  }
  base64MimeType(encoded) {
    var result = null;

    if (typeof encoded !== 'string') {
      return result;
    }

    var split = encoded.split('data:');
    var mime = split[1].split(';base64,');

    if (mime && mime.length) {
      result = [mime[0], mime[1]];
    }

    return result;
  }
}
