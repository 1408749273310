import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

import packageInfo from '../package.json';

import { BrowserTracing } from '@sentry/tracing'; // Must import second

if (environment.production as unknown as boolean) {
  enableProdMode();
}

Sentry.init(
  {
    dsn: environment.sentryUrl,

    // Set your release version, such as "getsentry@1.0.0"
    release: `lideres-centroamerica-app-${packageInfo.version}`,
    // Set your dist version, such as "1"
    dist: `${packageInfo.version}`,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    enabled: environment.production as unknown as boolean
  }
  // Forward the init method from @sentry/angular
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
