import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the RelativeTime pipe.
 *
 * See https://angular.io/docs/ts/latest/guide/_pipes.html for more info on
 * Angular Pipes.
 */
@Pipe({
  name: 'relativeTime',
})
export class RelativeTime implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  /**
   * Takes a value and makes it lowercase.
   */
  //transform(value: string, args) {
  transform(value: string) {
    if (!value) return 'NO_TIME';
    return this.timeDifference(new Date(), new Date(value));
  }

  /**
   * 相对时间长度
   * @param  {date} current  当前时间
   * @param  {date} previous 历史时间
   * @return {string}        相对时间长度
   */
  timeDifference(current, previous): string {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;
    if (this.translateService.getDefaultLang()==='es') {
      if (elapsed < msPerMinute) {
        if (Math.round(elapsed / 1000) < 3) return 'Ahora';
        return Math.round(elapsed / 1000) + 's atrás.';
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + 'm atrás.';
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + 'h atrás.';
      } else if (elapsed < msPerMonth) {
        if (Math.round(elapsed / msPerDay)===1)
          return (
            'Ayer a las ' + previous.getHours() + ':' + previous.getMinutes()
          );
        return Math.round(elapsed / msPerDay) + ' días atrás.';
      } else if (elapsed < msPerYear) {
        return '' + Math.round(elapsed / msPerMonth) + ' meses atrás.';
      } else {
        return this.dateFormat(previous);
      }
    } else {
      if (elapsed < msPerMinute) {
        if (Math.round(elapsed / 1000) < 3) return 'Now';
        return Math.round(elapsed / 1000) + 's ago';
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + 'm ago';
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + 'h ago';
      } else if (elapsed < msPerMonth) {
        if (Math.round(elapsed / msPerDay)===1)
          return (
            'Yesterday at ' + previous.getHours() + ':' + previous.getMinutes()
          );
        return Math.round(elapsed / msPerDay) + ' days ago';
      } else if (elapsed < msPerYear) {
        return 'about ' + Math.round(elapsed / msPerMonth) + ' month ago';
      } else {
        return this.dateFormat(previous);
      }
    }
  }

  dateFormat(value): string {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'dd-MM-yyyy HH:mm');
    return value;
  }
}
